import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import CameraAlt from '@mui/icons-material/CameraAlt';
import Logout from '@mui/icons-material/ExitToApp';
import { Replay } from '@mui/icons-material';
import { selectIsMobile, selectMainMenu } from 'store/crm/selectors';
import 'cropperjs/dist/cropper.css';
import { getCurrentUser, setMainMenu } from '../../../store/crm/actions';
import ProfilePhoto from './ProfilePhoto';
import './UserProfile.scss';
import { getToken } from '../../../store/auth/selectors';
import { CurrentUser } from '../../../containers/CurrentUser';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import languageMap from '../../../Language/Language';
import { editUserInfo, generateResetPasswordToken } from '../../../utils/actions';
import { setLoading } from '../../../store/global/actions';
import Back from '../../../assets/svg/Back';
import { MAIN_COLOR } from '../../../mainTheme';

const settingsContainer = {
  position: 'relative',
  maxWidth: 800,
  margin: '25px auto',
  borderBottom: '1px solid #C0C0C0',
  boxShadow: '0px 0px 5px #aaaaaa',
  backgroundColor: 'white',
  borderRadius: 2,
};

const labelName = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 30,
  color: '#FFF',
};

const bigNameContainer = {
  marginBottom: 15,
  background: MAIN_COLOR,
  height: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const containerStyle = {
  flexGrow: 1,
  overflow: 'auto',
  // display: 'flex',
  // flexDirection: 'column',
  alignItems: 'center',
};

const UserProfile = ({
  projectId,
  currentUser,
  token,
  setLoading,
  setMainMenu,
  isMobile,
  setUserAction,
  mainMenu,
}) => {
  const [image, setImage] = useState(null);
  const [profileImage, setProfileImage] = useState(currentUser.profile_image_url);
  const [language, setLanguage] = useState(currentUser.language);
  const [firstName, setFirstName] = useState(currentUser.first_name);
  const [lastName, setLastName] = useState(currentUser.last_name);
  const [email] = useState(currentUser.email);
  const [displayName, setDisplayName] = useState(currentUser.display_name);
  const { logout } = useContext(CurrentUser);
  const navigate = useNavigate();

  const onChangeImage = (e) => {
    const target = e.target;
    let files;
    e.preventDefault();
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      if (target.value) {
        target.value = '';
      }
    };
    reader.readAsDataURL(files[0]);
  };

  const onSave = async () => {
    try {
      setLoading(true);
      await editUserInfo(token, projectId, currentUser.user_id, {
        profile_image_url: profileImage,
        first_name: firstName,
        display_name: displayName,
        last_name: lastName,
        language,
      });
      setUserAction({
        ...currentUser,
        profile_image_url: profileImage,
        first_name: firstName,
        display_name: displayName,
        last_name: lastName,
        language,
      });
      setLoading(false);
      if (language !== currentUser.language) {
        localStorage.setItem('currentUserLanguage', language);
        window.location.reload();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const onChangeInfo = (onChange) => (event) => {
    onChange(event.target.value?.trim());
  };

  const onMacros = () => {
    if (mainMenu !== 'macros') {
      setMainMenu('macros');
    }
  };

  const onResetPassword = () => {
    let resetPasswordToken = generateResetPasswordToken(token, currentUser.email);
    resetPasswordToken.then((response) =>
      navigate('/reset_password?token=' + response.data, { replace: true })
    );
  };

  return (
    <div className="UserProfile" style={containerStyle}>
      <div style={settingsContainer}>
        {isMobile && (
          <Link to="/projects">
            <Back className="ProfileBack" />
          </Link>
        )}
        <div style={bigNameContainer}>
          <div className="ProfileCircle">
            {!!profileImage && <img className="ProfileImage" src={profileImage} alt="profile" />}
            <CameraAlt
              className="ProfileCamera"
              style={{ display: !profileImage ? 'block' : 'none' }}
            />
            <label htmlFor="ProfilePhotoInput" className="ProfileImageLabel" />
          </div>

          <div className="title-text" style={labelName}>
            <CameraAlt style={{ width: 30, height: 30, marginRight: 10 }} />
            {profileImage ? languageMap.profileChangePhoto : languageMap.profileAddPhoto}
          </div>
        </div>

        <input
          id="ProfilePhotoInput"
          type="file"
          onChange={onChangeImage}
          accept=".gif, .jpg, .jpeg, .png"
        />
        {image && (
          <ProfilePhoto
            token={token}
            imageSrc={image}
            onClose={() => {
              setImage(null);
            }}
            onChangeImage={setProfileImage}
          />
        )}
        <div className="ProfileInputContainer">
          <div style={{ display: 'flex' }}>
            <TextField
              className="ProfileInput"
              label={languageMap.profileFirstName}
              value={firstName}
              onChange={onChangeInfo(setFirstName)}
            />
            <TextField
              className="ProfileInput"
              label={languageMap.profileLastName}
              value={lastName}
              style={{ marginLeft: 30 }}
              onChange={onChangeInfo(setLastName)}
            />
          </div>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <TextField
              className="ProfileInput"
              label={languageMap.profileDisplayName}
              value={displayName}
              onChange={onChangeInfo(setDisplayName)}
            />
            <TextField
              className="ProfileInput"
              label={languageMap.profileEmail}
              InputProps={{
                readOnly: true,
              }}
              value={email}
              style={{ marginLeft: 30 }}
            />
          </div>

          <div style={{ display: 'flex', marginTop: 20 }}>
            <FormControl className="regular-text ProfileLanguageSelect">
              <InputLabel>{languageMap.profileLanguage}</InputLabel>
              <Select
                label={languageMap.profileLanguage}
                value={language}
                onChange={(event) => setLanguage(event.target.value)}
              >
                <MenuItem value={'ro'}>Română</MenuItem>
                <MenuItem value={'en'}>English</MenuItem>
              </Select>
            </FormControl>

            <Button
              className="ProfileSaveButton"
              style={{ marginTop: 15, marginLeft: 25 }}
              color="primary"
              variant="contained"
              onClick={onMacros}
            >
              {languageMap.projectDetailsMacros}
            </Button>
          </div>

          <div className="ProfileLogout">
            <div>
              <Button
                className="ProfileSaveButton"
                variant="contained"
                color="primary"
                onClick={onSave}
              >
                {languageMap.profilePhotoSave}
              </Button>
            </div>
            <div>
              <Button onClick={onResetPassword}>
                {languageMap.profileResetPassword}
                <Replay />
              </Button>
              <Button onClick={logout}>
                {languageMap.profileLogout}
                <Logout />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mainMenu: selectMainMenu(state),
  token: getToken(state),
  isMobile: selectIsMobile(state),
});

const mapDispatchToProps = {
  setMainMenu,
  setLoading,
  setUserAction: getCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
